import React from 'react'
import Download from '../components/DownloadSection'


const DownloadPage = () => {
    return (
        <>
          <Download/>  
        </>
    )
}

export default DownloadPage
