import pic1 from '../../images/svg-8.svg'
import pic2 from '../../images/svg-4.svg'
import pic3 from '../../images/svg-3.svg'
import pic4 from '../../images/svg-5.svg'
import pic6 from '../../images/svg-6.svg'
import pic5 from '../../images/svg-4.svg'

export const homeObjOne = {
    id: 'chats', 
    lightBg: false, 
    lightText: true, 
    lightTextDesc: true, 
    topLine: 'Courtspace Desk',
    headLine: 'Chat with customers',
    description: "Connect with customers via chat. It is the best way to increase engagement. Everyone says it's cool.",
    buttonLabel: 'Get started',
    imgStart: false,
    img: pic1,
    alt: 'Car',
    dark: true,  
    dark2:true,
    primary: true, 
    darkText: false,
    playersNavFlag:false


};

export const homeObjTwo = {
    id: 'rentals', 
    lightBg: true, 
    lightText: false, 
    lightTextDesc: false, 
    topLine: 'Courtspace Desk',
    headLine: 'Receive rental and confirmation requests ',
    description: "Get paid even when customers don't show up. Charge no-show fees through the app. Receive requests from Courtspace users.",
    buttonLabel: 'Get started',
    imgStart: false,
    img: pic2,
    alt: 'Car',
    dark: false,  
    dark2:true,
    primary: false, 
    darkText: true,
    playersNavFlag:false

};

export const homeObjThree = {
    id: 'signup', 
    lightBg: true, 
    lightText: false, 
    lightTextDesc: false, 
    topLine: 'Courtspace Desk',
    headLine: 'Customers pay you with scanning QR Code',
    description: 'Save up to 0.5% in credit card processing fees.',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: pic3,
    alt: 'Car',
    dark: false,  
    dark2:true,
    primary: false, 
    darkText: true,
    playersNavFlag:false,


}

export const homeObjPlayersOne = {
    id: 'players1', 
    lightBg: false, 
    lightText: true, 
    lightTextDesc: true, 
    topLine: 'Courtspace',
    headLine: 'Chat with players, collegues, sport facilities and gyms in your area',
    description: 'Start chatting with your favorite sports facilities, players and event organizers near you.',
    buttonLabel: 'Download Now!',
    imgStart: false,
    img: pic4,
    alt: 'Car',
    dark: true,  
    dark2:true,
    primary: true, 
    darkText: false,
    playersNavFlag:true,

}

export const homeObjPlayersTwo = {
    id: 'players2', 
    lightBg: true, 
    lightText: false, 
    lightTextDesc: false, 
    topLine: 'Courtspace',
    headLine: "Get Courtspace Pass for just $49.99/month!",
    description: 'Join and play sports, take classes and more with Courtspace-Pass, a monthly subscription plan for unlimited games and classes in your area',
    buttonLabel: 'Get Rewards!',
    imgStart: false,
    img: pic6,
    alt: 'Car',
    dark: false,  
    dark2:true,
    primary: false, 
    darkText: true,
    playersNavFlag:true,


}

export const homeObjPlayersThree = {
    id: 'players3', 
    lightBg: false, 
    lightText: true, 
    lightTextDesc: true, 
    topLine: 'Courtspace',
    headLine: 'Weekly pickup games and fitness classes',
    description: 'Courtspace partners with gyms, fitness studios and sports facilities so you have all-access to pickup games, classes and gym time!',
    buttonLabel: 'Download Now!',
    imgStart: false,
    img: pic5,
    alt: 'Car',
    dark: true,  
    dark2:true,
    primary: true, 
    darkText: false,
    playersNavFlag:true,

}