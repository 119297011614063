import React from 'react'
import {SidebarContainer, 
    Icon, 
    CloseIcon, 
    SidebarWrapper, 
    SidebarMenu, 
    SidebarLink, 
    SideBtnWrap, 
    SidebarRoute,
    SidebarLink2,
} from './SidebarElements'

function Sidebar({isOpen, toggle}) {
    return (
        <SidebarContainer isOpen ={isOpen} onClick={toggle}>
            <Icon onClick ={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='players1' onClick={toggle}>
                        Chats
                    </SidebarLink>
                    <SidebarLink to='players2' onClick={toggle}>
                        Courtspace-Pass
                    </SidebarLink>
                    <SidebarLink to='players3' onClick={toggle}>
                        Weekly-Events
                    </SidebarLink>
                    <SidebarLink2 to='/download' onClick={toggle}>
                        Sign up
                    </SidebarLink2>
                </SidebarMenu>
                
            </SidebarWrapper>
            <SideBtnWrap>
                    <SidebarRoute to='/download'>
                        Sign in
                    </SidebarRoute>
            </SideBtnWrap>
        </SidebarContainer>
    )
}

export default Sidebar
